<template>
  <div class="container">
    <div class="topSearch" style="font-size: 14px">
      <div style="margin-right: 10px">
        当前会员号:
        <!-- {{ memberId }} ({{nickname}}) -->
      </div>
      <el-select
        @change="huiayu"
        v-model="memberId"
        placeholder="请选择"
        clearable
        filterable
        remote
        reserve-keyword
        :remote-method="remoteMethod"
        value-key="memberId"
      >
        <el-option
          v-for="(item, index) in memberList"
          :label="item.memberId + ' : (' + item.nickname + ')'"
          :value="item.memberId"
          :key="index"
        >
          {{ item.memberId }} : ({{ item.nickname }})
        </el-option>
      </el-select>
    </div>
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addBtns">新建</el-button>
      <el-button class="itemBtn btnColor" @click="editBtns">修改</el-button>
      <el-button class="itemBtn btnColor" @click="delBtns">删除</el-button>
      <el-button class="itemBtn btnColor">地址管理</el-button>
    </div>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 新增修改会员 -->
    <el-dialog :title="idatitls" :visible.sync="addDiashow" @close="closedisa">
      <div class="newForm">
        <el-form
          label-width="120px"
          ref="userarray"
          :model="formDatas"
          :rules="rules"
          status-icon
        >
          <div class="formTable">
            <div class="queryItem1">
              <el-form-item label="公司名称:">
                <el-input
                  v-model="formDatas.corporateName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="收件人:" prop="fullName">
                <el-input
                  v-model="formDatas.fullName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="formTable">
            <div class="queryItem1">
              <el-form-item label="联系固话:">
                <el-input
                  v-model="formDatas.tel"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="联系手机:" prop="phone">
                <el-input
                  v-model="formDatas.phone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="formTable">
            <div class="queryItem1">
              <el-form-item label="国家/地区:" prop="areaCountryId">
                <el-select
                  v-model="formDatas.areaCountryId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in guoJiaList"
                    :label="item.chineseName"
                    :value="item.areaCountryId"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="州/省:" prop="provinceName">
                <el-input
                  v-model="formDatas.provinceName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="formTable">
            <div class="queryItem1">
              <el-form-item label="城市:" prop="cityName">
                <el-input
                  v-model="formDatas.cityName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="税号:">
                <el-input
                  v-model="formDatas.taxNumber"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="formTable">
            <div class="queryItem1">
              <el-form-item label="邮政编码:" prop="postalCode">
                <el-input
                  v-model="formDatas.postalCode"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="queryItem1 queryItemText">
            <el-form-item label="详细地址:" prop="address">
              <el-input
                type="textarea"
                rows="2"
                v-model="formDatas.address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="">
            <el-checkbox
              style="margin-top: 14px; margin-left: -20px"
              v-model="checked"
              >设为默认地址</el-checkbox
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="footbox">
        <el-button class="seniorbut" @click="confirmbutAdd">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
export default {
  components: { paging },
  data() {
    return {
      tabelHeadeTitle: [
        {
          name: "公司名称",
          field: "corporateName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人",
          field: "fullName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "联系电话",
          field: "phone",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "邮政编号",
          field: "postalCode",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "国家",
          field: "chineseName",
          width: "100",
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: "州/省",
          field: "provinceName",
          width: "100",
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: "城市",
          field: "cityName",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: "地址",
          field: "address",
          width: "160",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      myTableHeard: [],
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      userId: "",
      nickName: "",
      memberId: "",
      pagetTypa: "",
      memberList: [],

      addDiashow: false,
      formDatas: {
        address: "",
        areaCountryId: "",
        cityName: "",
        fullName: "",
        phone: "",
        postalCode: "",
        provinceName: "",
        //上面必填
        corporateName: "", //公司名称
        isDefault: 0, // 是否默认0=否1=是
        taxNumber: "", //税号
        tel: "", //固话
        userAddressId: "", //修改必传
      },
      rules: {
        address: [{ required: true, message: "请输入内容", trigger: "blur" }],
        areaCountryId: [
          { required: true, message: "请输入内容", trigger: "change" },
        ],
        cityName: [{ required: true, message: "请输入内容", trigger: "blur" }],
        fullName: [{ required: true, message: "请输入内容", trigger: "blur" }],
        phone: [{ required: true, message: "请输入内容", trigger: "blur" }],
        postalCode: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        provinceName: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      idatitls: "新建地址",
      checked: false, //是否默认地址
      guoJiaList: [], //国家列表
      selectTableData: [], //表格已选数据
    };
  },
  created() {
    console.log("create");
    this.myTableHeard = this.tabelHeadeTitle;
    let querys = this.$route.query || "";
    console.log(querys);
    if (querys && querys.userId) {
      this.userId = querys.userId;
      this.nickName = querys.nickName;
      this.formDatas.fullName = querys.nickName;
      this.memberId = querys.memberId;
      this.pagetTypa = querys.pagetTypa;
      this.getData();
    }
    this.getVipList();
    this.getGuoJiaList();
  },
  activated() {
    console.log("activated");
    let querys = this.$route.query || "";
    console.log(querys);
    if (querys && querys.userId) {
      this.userId = querys.userId;
      this.nickName = querys.nickName;
      this.formDatas.fullName = querys.nickName;
      this.memberId = querys.memberId;
      this.pagetTypa = querys.pagetTypa;
      this.getData();
    }
  },
  mounted() {},
  watch: {},
  methods: {
    getData() {
      let that = this;
      Api.userAddressList({ userId: this.userId }).then((res) => {
        this.tableData = res.data.result || [];
        this.total = this.tableData.length || 0;
        that.$nextTick(() => {
          that.$refs.mytable.doLayout();
        });
      });
    },
    // 新增
    addBtns() {
      this.idatitls = "新建会员地址";
      this.addDiashow = true;
    },
    // 修改地址
    editBtns() {
      if (this.selectTableData.length < 1) {
        this.$message.warning("您还没有选择任何地址");
      } else if (this.selectTableData.length > 1) {
        this.$message.warning("一次只能修改一个地址哦");
      } else {
        // 获取详情
        let userAddressId = this.selectTableData[0].userAddressId;
        Api.userAddressDetail({ userAddressId: userAddressId }).then((res) => {
          this.idatitls = "修改会员地址";
          this.addDiashow = true;
          this.formDatas = res.data.result;
          this.formDatas.userAddressId = userAddressId;
          if (res.data.result.isDefault == 1) {
            this.checked = true;
          } else {
            this.checked = false;
          }
        });
      }
    },
    // 删除地址
    delBtns() {
      if (this.selectTableData.length < 1) {
        this.$message.warning("您还没有选择任何地址");
      } else {
        let ids = [];
        this.selectTableData.forEach((item) => {
          ids.push(item.userAddressId);
        });
        // 获取详情
        this.$confirm("是否删除该地址?", "提示:")
          .then(() => {
            let param = {
              userAddressId: ids,
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.deleteUserAddress(param).then((res) => {
              this.$message.success(res.data.message || "删除成功");
              // this.addDiashow = false;
              this.getData();
            });
          })
          .catch(() => {});
      }
    },

    // 确定保存
    confirmbutAdd() {
      if (this.checked) {
        this.formDatas.isDefault = 1;
      } else {
        this.formDatas.isDefault = 0;
      }
      // 公共参数
      let param = {
        address: this.formDatas.address,
        areaCountryId: this.formDatas.areaCountryId,
        cityName: this.formDatas.cityName,
        fullName: this.formDatas.fullName,
        phone: this.formDatas.phone,
        postalCode: this.formDatas.postalCode,
        provinceName: this.formDatas.provinceName,
        //上面必填
        corporateName: this.formDatas.corporateName, //公司名称
        isDefault: this.formDatas.isDefault || 0, // 是否默认0=否1=是
        taxNumber: this.formDatas.taxNumber, //税号
        tel: this.formDatas.tel, //固话
      };
      if (this.idatitls == "新建会员地址") {
        param.userId = this.userId;
        let sign = tools.getSign(param);
        param.sign = sign;
        Api.addUserAddress(param).then((res) => {
          this.$message.success(res.data.message || "新建成功");
          this.addDiashow = false;
          this.getData();
        });
      } else {
        // userAddressId
        param.userAddressId = this.formDatas.userAddressId;
        let sign1 = tools.getSign(param);
        param.sign = sign1;
        // 修改
        Api.updateUserAddress(param).then((res) => {
          this.$message.success(res.data.message || "修改成功");
          this.addDiashow = false;
          this.getData();
        });
      }
    },
    // 关闭弹窗
    closedisa() {
      this.addDiashow = false;
      this.formDatas = {
        address: "",
        areaCountryId: "",
        cityName: "",
        fullName: "",
        phone: "",
        postalCode: "",
        provinceName: "",
        //上面必填
        corporateName: "", //公司名称
        isDefault: 0, // 是否默认0=否1=是
        taxNumber: "", //税号
        tel: "", //固话
        userAddressId: "", //修改必传
      };
    },
    // 国家下拉列表
    getGuoJiaList() {
      Api.getAreaContryList_q().then((res) => {
        this.guoJiaList = res.data.result || [];
      });
    },

    //远程搜索(国家)
    // remoteMethod_gj(e) {
    //   if (e.length > 2) {
    //     this.getGuoJiaList(e);
    //   }
    // },
    //远程搜索(会员)
    remoteMethod(e) {
      if (e.length > 2) {
        this.getVipList(e);
      }
    },
    // 选择会员号
    huiayu(e) {
      this.memberList.forEach((item) => {
        if (e == item.memberId) {
          this.nickname = item.nickname;
          this.userId = item.userId;
          this.getData();
        }
      });
    },
    // 获取会员列表
    getVipList(e) {
      //  获取会员列表

      Api.getMemberDownList({ memberId: e || "" }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
        }
      });
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
      ////console.log(e);
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.newForm {
  // margin-left: 80px;
  .formTable {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    // flex-wrap: wrap;
  }
  .queryItem1 {
    width: 42%;
    margin-top: 20px;
    margin-left: 30px;
    // margin: 20px auto 0;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
  .queryItemText {
    margin-left: 50px;
    width: 85%;
  }
}

// .queryItem1 {
//   width: 100%;
//   margin: 0 3%;
//   margin-top: 10px;
// }

.footbox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.topSearch {
  display: flex;
  align-items: center;
  ::v-deep {
    .el-select {
      width: 240px;
    }
  }
}

::v-deep {
  .el-dialog {
    width: 60%;
  }
}
</style>
